export const environment = {
    pageName: 'MIA CMS',
    appFlavor: 'mia',
    production: true,
    appEnv: 'qa',
    googleMapsKey: 'AIzaSyDv2moezp7Zp-JYOEXS6zA6SlfS0mt6e8c',
    apiConfig: {
        projectEndpoint: 'https://mia-api-qa.itxi.aero',
        apiKey: 'd7793e08-5c3e-49a1-ad48-93e172c9f3e5',
    },
    firebase: {
        apiKey: 'AIzaSyDv2moezp7Zp-JYOEXS6zA6SlfS0mt6e8c',
        authDomain: 'miami-app-dev.firebaseapp.com',
        databaseURL: 'https://miami-airport-official-qa.firebaseio.com',
        projectId: 'miami-app-dev',
        storageBucket: 'miami-airport-official-qa',
        messagingSenderId: '1030264903298',
        appId: '1:1030264903298:web:575589ac59fbed7d46c69b',
        measurementId: 'G-WTPREJV6RH',
    },
    googleAnalyticsTrackId: '',
    rolesWithLoginAccess: [
        {
            role: 'admin',
            route: 'dashboard/content/users',
            node: 'users',
        },
        {
            role: 'power_user',
            route: 'dashboard/content/airlines',
            node: 'airlines',
        },
        {
            role: 'viewer',
            route: 'dashboard/content/airlines',
            node: 'airlines',
        },
    ],
    sessionTokenCheckInterval: 300000,
    getAllFlightsListInterval: 300000,
    sessionTokenExtension: true,
    defaultRoute: 'dashboard/content/users',
    enableRandomBackground: false,
    isGoogleAnalyticEnabled: false,
    airportCoordinates: {
        latitude: 25.7947591,
        longitude: -80.27876946,
        ISO3166_1_Alpha_2: 'LB',
    },
    videoCategories: [],
    userTableLanguages: [{ key: 'en', label: 'LANGUAGES.ENGLISH_ONLY' }],
    userTablesRole: [
        { key: 'admin', label: 'ROLES.ADMIN' },
        { key: 'power_user', label: 'ROLES.POWER_USER' },
        {
            key: 'viewer',
            label: 'ROLES.VIEWER',
        },
    ],
    formLanguages: [
        { key: 'en', title: 'LANGUAGES.ENGLISH', label: 'FORMS.EN_LANG' },
        { key: 'es', title: 'LANGUAGES.ESPANOL', label: 'FORMS.ES_LANG' },
        { key: 'de', title: 'LANGUAGES.DEUTSCH', label: 'FORMS.DE_LANG' },
        { key: 'hu', title: 'LANGUAGES.HUNGRIAN', label: 'FORMS.HU_LANG' },
    ],
    microsoftSSOClientId: '',
    googleSSOClientId: '',
    signInOptions: ['password'],
    appCategories: [],
    payment_method: [],
    payment_status: [],
    orientationArray: [],
    containerType: [],
    contentType: [],
    contentItems: [],
    equipmentType: [],
    departureTime: [],
    sectors: [],
    cartItems: [],
};
